<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/userAgreement/en">User Agreement</router-link> |
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hello',
  props: {
    msg: String
  }
}
</script>

<style scoped>
  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #42b983;
  }

  #nav a.router-link-exact-active {
    color: #2c3e50;
  }
</style>
