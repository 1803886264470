<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" width="200">
    <Hello msg="Welcome to Infinity Wallet Agreement"/>
  </div>
</template>

<script>
import Hello from '@/components/Hello.vue'

export default {
  name: 'Home',
  components: {
    Hello
  }
}
</script>

<style scoped lang="less">
 .home  {
   text-align: center;
   padding-top: 30px;
 }
</style>
